interface TikTokPixel {
  identify: (params: {
    email?: string
    phone_number?: string
    external_id?: string
  }) => void
  track: (event: string, params: Record<string, any>) => void
}

declare global {
  interface Window {
    ttq?: TikTokPixel
  }
}

// Helper function for SHA-256 hashing
const hashValue = async (value: string): Promise<string> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(value)
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
}

// Function to identify user
export const tiktokPixel = {
  identifyUser: async (params: {
    email?: string
    phoneNumber?: string
    externalId?: string
  }) => {
    const hashedParams = {
      email: params.email ? await hashValue(params.email) : undefined,
      phone_number: params.phoneNumber
        ? await hashValue(params.phoneNumber)
        : undefined,
      external_id: params.externalId
        ? await hashValue(params.externalId)
        : undefined,
    }

    window.ttq?.identify(hashedParams)
  },

  trackViewContent: () => {
    window.ttq?.track("ViewContent", {})
  },

  trackInitiateCheckout: () => {
    window.ttq?.track("InitiateCheckout", {})
  },

  trackCompleteRegistration: () => {
    window.ttq?.track("CompleteRegistration", {})
  },

  trackPlaceAnOrder: (params: {
    contents: Array<{
      contentId: string
      contentType: string
      contentName: string
    }>
    value: number
    currency: string
  }) => {
    window.ttq?.track("PlaceAnOrder", {
      contents: params.contents.map((item) => ({
        content_id: item.contentId,
        content_type: item.contentType,
        content_name: item.contentName,
      })),
      value: params.value,
      currency: params.currency,
    })
  },
}
